import { FC, useState } from 'react';
import Link from 'next/link';
import styles from './BookCardItem.module.scss';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { getAuthorFullName } from '@/helpers/helpers';
import { IBook, IAuthor } from '@/interfaces/home.interface';
import { parseISO, format } from 'date-fns';
import { getTruncatedTxt } from '@/helpers/helpers';

interface IBookCardItem {
  book: IBook | any;
  author: IAuthor | any;
  rating: number;
  id: number;
  createdAt?: string;
  dataName?: string;
  isPodcast?: boolean;
  isAuthor?: boolean;
}

const BookCardItem: FC<IBookCardItem> = ({ id, book, author, rating, createdAt, dataName, isPodcast, isAuthor }) => {
  const { title, description, external_id } = book;
  const { first_name, middle_name, last_name, id: authorId } = author;
  const authorFullName = getAuthorFullName(first_name, middle_name, last_name);
  const bookRating = Number(rating);

  const bookFullAlias = !!isPodcast ? `/library/podcasts/${id}` : `/book/${id}`;
  const authorFullAlias = `/library/author/${authorId}`;

  const [isCardShowInfo, setIsCardShowInfo] = useState(false);

  return (
    <div className={isCardShowInfo ? `${styles.bookCard} ${styles.bookCard_infoActive}` : `${styles.bookCard}`} data-card={dataName}>
      <div className={styles.bookCard__wrap}>
        <div className={styles.bookCard__image}>
          <Link prefetch={false} className={styles.bookCard__imageLink} href={bookFullAlias}>
            <img loading="lazy" width="174" height="247" src={`${DEV_ABSOLUTE_URL}/covers_185/${external_id}.jpg`} alt={title} />
          </Link>
          <span className={styles.bookCard__mobButton} onClick={() => setIsCardShowInfo(true)}></span>
          {/* {% include "blocks/draft_sticker.twig" %} */}
        </div>

        <div className={styles.bookCard__desc}>
          <div className={styles.bookCard__title}>
            <Link prefetch={false} href={bookFullAlias}>
              {title}
            </Link>
          </div>
          <div className={styles.bookCard__author}>
            {isAuthor ? (
              <div className={`${styles.bookCard__authorLink} ${styles.bookCard__authorLink_noHover}`}>{authorFullName}</div>
            ) : (
              <Link prefetch={false} className={styles.bookCard__authorLink} href={authorFullAlias}>
                {authorFullName}
              </Link>
            )}
          </div>

          <div className={bookRating > 1 ? `${styles.bookCard__rating}` : `${styles.bookCard__rating} ${styles.bookCard__rating_zero}`}>
            <span className={styles.bookCard__ratingNumber}>{bookRating > 5 ? 5 : bookRating}</span>
          </div>
          {!!createdAt && createdAt!.length > 0 && (
            <div className={`${styles.bookCard__addDate} ${styles.bookCard__addDate_tile}`}>
              Добавлена: <time dateTime={createdAt}>{format(parseISO(createdAt!), 'dd.MM.yyyy')}</time>
            </div>
          )}
          <div className={styles.bookCard__txt}>{getTruncatedTxt(description, 250)}</div>
          <div className={styles.bookCard__hideButton} onClick={() => setIsCardShowInfo(false)}></div>
        </div>
      </div>
      <div className={`${styles.bookCard__txt} ${styles.bookCard__txt_mob}`}>{getTruncatedTxt(description, 250)}</div>
      {!!createdAt && createdAt!.length > 0 && (
        <div className={styles.bookCard__addDate}>
          Добавлена: <time dateTime={createdAt}>{format(parseISO(createdAt!), 'dd.MM.yyyy')}</time>
        </div>
      )}
    </div>
  );
};

export default BookCardItem;
